<template>
  <div class="wrap" v-show="lang">
    <Header />
    <loginPC v-if="platform.isPC" />
    <loginM v-else />
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import loginPC from "./components/loginPC";
import loginM from "./components/loginM";
import platform from "@/utils/platform";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    loginPC,
    loginM,
  },
  computed: {
    ...mapState(["lang"]),
    isZhCN() {
      const isCN = this.lang === "zh-CN";
      return isCN;
    },
  },
  data() {
    return {
      platform,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/util.scss";

.wrap {
  @extend .iphoneX;
}
</style>
