<template>
  <div class="wrap_wrap">
<!--    <img src="@assets/imgs/loginBgc.png" alt="" class="bgcimg" />-->
    <div class="wrap">
      <div class="title">
        {{ $t("login.登录") }}
      </div>
      <el-form ref="form" :model="form">
        <el-form-item
          prop="account"
          :rules="[{ required: true, message: $t('login.请输入电话或邮箱') }]"
        >
          <el-input
            :readonly="readonly"
            @focus="readonly = false"
            v-model="form.account"
            :placeholder="$t('login.账号')"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="form-item-2"
          prop="password"
          :rules="[{ required: true, message: $t('login.请输入密码') }]"
        >
          <el-input
            :readonly="readonly"
            @focus="readonly = false"
            type="password"
            v-model="form.password"
            show-password
            :placeholder="$t('login.密码')"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="captcha"
          :rules="[{ required: true, message: $t('register.请输入验证码') }]"
          class="form-item-code"
        >
          <el-input
            :readonly="readonly"
            @focus="readonly = false"
            v-model="form.captcha"
            :placeholder="$t('register.验证码')"
            ><i slot="prefix" class="el-input__icon el-icon-tickets"></i
          ></el-input>
          <img :src="codeUrl" @click="getCode({})" class="login-code-img" />
        </el-form-item>
        <div class="extra">
          <div class="extra-item" @click="findPassword">
            {{ $t("login.忘记密码") }}?
          </div>
          <div class="extra-item" @click="onRegister">
            {{ $t("login.还未注册") }}？
          </div>
        </div>
        <el-form-item>
          <el-button class="btn" @click="submit">
            {{ $t("login.确认") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { login, captcha } from "@service/api";
import Message from "@utils/Message";
import { goto, parseQueryString } from "@utils/goto";
export default {
  name: "App",
  components: {},
  data() {
    return {
      readonly: true,
      codeUrl: "",
      form: {
        captcha: "",
        account: "",
        password: "",
        type: 1,
        uuid: "",
      },
    };
  },
  mounted() {
    console.log(location.search)
    console.log(parseQueryString())
    this.getCode({});
  },
  methods: {
    onRegister() {
      goto("/register");
    },
    findPassword() {
      goto("/forgotPassword");
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        try {
          const res = await login(this.form);
          console.log(res);
          localStorage.setItem("MODE_accessToken", res);
          // const { backpage } = parseQueryString();
          // const url = backpage || "/";
          // goto(url, true);
          goto('/consult', true);
        } catch (e) {
          Message.fail(e.msg);
        }
      });
    },
    async getCode(params) {
      try {
        const res = await captcha(params);
        this.codeUrl = "data:image/gif;base64," + res.imgIO;
        this.form.uuid = res.uuid;
        // console.log(res);
      } catch (e) {
        Message.fail(e.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

.wrap_wrap {
  position: relative;
  width: 100vw;
  margin: 0;
  padding: 0;
  // height: 100vh;
  // @include flex-center();
  .bgcimg {
    width: 100%;
  }
  img {
    vertical-align: top;
  }
}
.wrap {
  //position: absolute;
  //left: 50%;
  //top: 40%;
  //transform: translate(-50%, -50%);
  width: 100%;
  margin-top: px2rem(40px);
  background: rgba(38, 38, 38, 1);
  //border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .title {
    margin-bottom: 25px;
    font-size: 35px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #fff;
  }

  .extra {
    display: flex;
    justify-content: space-between;
    position: relative;
    bottom: 10px;
    margin-top: 9px;
    line-height: 22px;
    .extra-item {
      cursor: pointer;
      font-size: 16px;
      text-decoration: underline;
      color: #c0c4cc;
      margin-bottom: 7px;
    }
  }

  .form-item-code {
    display: flex;
    position: relative;
    img {
      width: 110px;
      position: absolute;
      right: 0;
    }
  }

  .btn {
    width: 368px;
    height: 50px;
    background: rgba(32, 32, 32, 1);
    border-radius: 11px;
    font-size: 20px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #ffffff;
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
  }
}
.wrap::v-deep {
  .el-form-item {
    margin-bottom: 30px;
  }

  .el-input__inner {
    width: 368px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid rgba(109, 109, 109, 1);
    background-color: rgba(32, 32, 32, 1);
    color: #fff;
  }
  .el-form-item__content {
    width: 368px;
  }
  .el-input__inner:focus {
    border: 1px solid #fff;
  }
}
.form-item-code::v-deep {
  .el-input__inner,
  .el-input {
    width: 240px;
  }
}
</style>
